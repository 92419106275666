import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useCookies } from '@/composables/useCookies';
import { useResourceFilters } from '@/composables/useResourceFilters';
import { useResourceMapper } from '@/composables/useResourceMapper';
import { useProfileStore } from '@/stores/profile/profileStore';
import { Cookie, Route } from '@/types';

export const useDeveloperTools = () => {
  const { isCookieValueTruthyNumber: isDeveloperToolsEnabled } = useCookies(
    Cookie.ADDONS_ENABLED,
  );
  const route = useRoute();
  const { activeDeveloperToolsResources } = useResourceMapper();
  const { hasCloudHosting, hasDeveloperTools } = useResourceFilters();
  const profileStore = useProfileStore();

  const hasAddonsEnabledThroughCloudPlan = computed(
    () => isDeveloperToolsEnabled.value && hasCloudHosting.value,
  );

  const canManageDeveloperToolsSubscriptions = computed(
    () => !profileStore.isAccessManager && !hasCloudHosting.value,
  );

  const hasAccessToAllWebsites = computed(
    () =>
      activeDeveloperToolsResources.value.some(
        ({ config }) => config?.tools?.[0]?.maxWebsiteQuantity === -1,
      ) || hasCloudHosting.value,
  );

  const isEligibleForTrial = computed(
    () => !hasDeveloperTools.value && !hasCloudHosting.value,
  );

  const hasAccessToDeveloperToolsDashboard = computed(
    () =>
      isDeveloperToolsEnabled.value &&
      (activeDeveloperToolsResources.value.length > 0 || hasCloudHosting.value),
  );

  const developerToolsRoute = computed(() => {
    if (!hasAccessToDeveloperToolsDashboard.value) {
      return Route.DeveloperTools.PURCHASE;
    }

    const isInDashboard = route.matched.some(
      ({ name }) => name === Route.DeveloperTools.DASHBOARD,
    );
    const currentRouteName = route.name ?? undefined;

    if (!isInDashboard) {
      return Route.DeveloperTools.DASHBOARD;
    }

    return currentRouteName;
  });

  return {
    hasAccessToAllWebsites,
    canManageDeveloperToolsSubscriptions,
    isDeveloperToolsEnabled,
    isEligibleForTrial,
    hasAccessToDeveloperToolsDashboard,
    developerToolsRoute,
    hasAddonsEnabledThroughCloudPlan,
  };
};
