import cookies from 'js-cookie';

import { useBrands } from '@/composables';
import { useLanguageStore } from '@/stores';
import type { TLanguageCode } from '@/types';
import {
  AVAILABLE_LANGUAGE_CONFIGURATIONS,
  Cookie,
  LANGUAGE_CODE,
} from '@/types';

const EN_US = 'en_US';

type TLanguageCodeWithUS = TLanguageCode | typeof EN_US;

export const useLanguages = () => {
  const languageStore = useLanguageStore();
  const { isCoUkBrand } = useBrands();

  const getCookieLanguage = () => {
    const languageFromCookie = cookies.get(Cookie.LANGUAGE);

    return languageFromCookie !== undefined &&
      (Object.values(LANGUAGE_CODE) as string[]).includes(languageFromCookie)
      ? (languageFromCookie as TLanguageCodeWithUS)
      : undefined;
  };

  const isEnglishWithoutBrand = (languageCode: TLanguageCodeWithUS) =>
    languageCode === LANGUAGE_CODE.en_GB && !isCoUkBrand.value;

  const getCurrentLanguageCode = () => {
    const languageCode =
      getCookieLanguage() || languageStore.currentLanguage || EN_US;

    if (isEnglishWithoutBrand(languageCode)) {
      return EN_US;
    }

    return languageCode;
  };

  const getLanguageByCode = (languageCode: TLanguageCodeWithUS) => {
    if ([EN_US, LANGUAGE_CODE.en_GB].includes(languageCode)) {
      return AVAILABLE_LANGUAGE_CONFIGURATIONS.find(
        ({ code }) => code === 'en_GB',
      );
    }

    return AVAILABLE_LANGUAGE_CONFIGURATIONS.find(
      ({ code }) => code === languageCode,
    );
  };

  const getLanguageNameByCode = (languageCode: TLanguageCodeWithUS) => {
    if ([EN_US, LANGUAGE_CODE.en_GB].includes(languageCode)) {
      return 'English';
    }

    return AVAILABLE_LANGUAGE_CONFIGURATIONS.find(
      ({ code }) => code === languageCode,
    )?.name;
  };

  const getLanguageIcon = (languageCode: TLanguageCodeWithUS) =>
    isEnglishWithoutBrand(languageCode) ? EN_US : languageCode;

  const getLanguageIconPath = (languageCode: TLanguageCodeWithUS) =>
    `@/images/languageCodes/${getLanguageIcon(languageCode).toLowerCase()}.svg`;

  const isCurrentLanguageEnglish = () => {
    const currentLanguage = getCurrentLanguageCode();

    return [EN_US, LANGUAGE_CODE.en_GB].includes(currentLanguage);
  };

  return {
    getCurrentLanguageCode,
    getLanguageByCode,
    getLanguageNameByCode,
    getLanguageIcon,
    getLanguageIconPath,
    isCurrentLanguageEnglish,
  };
};
